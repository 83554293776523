import React, { useRef } from "react"
import { graphql, Link } from "gatsby"

import Layout from '../components/layout'

import AboutUs from "../components/AboutUs/AboutUs"
import Details from "../components/AboutUs/Details"
import Advantages from "../components/AboutUs/Advantages"
import Extras from "../components/AboutUs/Extras"

function AboutPageTemplate(props) {
    console.log(props)
    let data = props.data;

    // console.log(data)


    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://trustdev.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://trustdev.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }


    return (
        <Layout
            headerMenu={props.pageContext.headerMenu}
            footerMenu={props.pageContext.footerMenu}
            currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
            availableVersions={props.pageContext.availablePages}
            globalInfo={data.wpTemplate.globalInformation}
            locale={props.pageContext.language}
            metaData={constructMetaData(data.wpPage, props.pageContext.currentPage, props.pageContext.language)}
        // services={props.pageContext.servicesCategories}
        >
            <div>
                <section id="firstSection" className="pt-8">
                    <AboutUs
                        data={data.wpPage.aboutPageAcf?.aboutIntro}
                    />
                </section>

                <section>
                    <Details
                        data={data.wpPage.aboutPageAcf?.detailsSection}
                    />
                </section>

                <section>
                    <Advantages
                        data={data.wpPage.aboutPageAcf?.advantagesSection}
                    />
                </section>

                <section>
                    <Extras
                        data={data.wpPage.aboutPageAcf?.extrasSection}
                    />
                </section>
            </div>
        </Layout>
    )
}

export default AboutPageTemplate

export const pageQuery = graphql`query GET_ABOUT_PAGE($id: String, $templateId: String) {
    wpPage(id: { eq: $id}){
        seo {
            canonical
            title
            metaDesc
            opengraphDescription
            opengraphTitle
            opengraphImage {
                sourceUrl
            }
        }
        title
        aboutPageAcf{
            aboutIntro{
                h1
                content
                image{
                    sourceUrl
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH, quality: 100)
                        }
                    }
                }
            }
            detailsSection{
                details{
                    title
                    content
                }
            }
            advantagesSection{
                title
                content
                image{
                    sourceUrl
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
                        }
                    }
                }
            }

            extrasSection {
                title
                details{
                    title
                    content
                }
            }
        }
    }

    wpTemplate(slug: {eq: $templateId}) {
		globalInformation {
            contactInfo {
                phone {
                    label
                    text
                }
                address {
                    label
                    text
                }
                email {
                    label
                    text
                }
                workingHours {
                    label
                    text
                }
            }
            contactForm{
                title
                content
                socialLinks{
                    social{
                        type
                        link
                    }
                }
                form{
                    label
                    type
                    errorMessage
                    value
                }
                formCta
            }
            footerExtra {
                description
                copyright
            }
        }
    }
}
`
