import React, { useState } from 'react'
import "./Details.css";

function Extras(props) {
    const data = props.data;

    return (
        <div className="m-auto container-cs text-left relative px-4 md:px-0 py-16 md:py-32">
            <div className='pb-2'>
                <h2 className='w-full text-center' >
                    {data.title}
                </h2>
                <div className='spacer-yellow m-auto'><div><div style={{ height: "3px" }}></div></div></div>
            </div>
            <div className='flex flex-wrap w-full'>
                {data.details && data.details.map((det, i) => (
                    <div className={`w-full md:w-1/2 pt-8 md:pt-12 pr-0 md:pr-8`} key={`about-details-${i}`}>
                        <div className={`w-full pb-8`}>
                            <h3>{det.title}</h3>
                        </div>
                        <div className={`w-full`}>
                            <div className='ss-content' dangerouslySetInnerHTML={{ __html: det.content }} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Extras;