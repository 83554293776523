import React, { useState } from 'react'
import "./AboutUs.css";
import { GatsbyImage } from "gatsby-plugin-image";

function AboutUs(props) {
    const data = props.data;
    console.log(data)

    return (
        <div className='container-cs m-auto'>
            <div>
                <GatsbyImage
                    alt={data.image.altText}
                    loading={"eager"}
                    image={data.image.localFile.childImageSharp.gatsbyImageData}
                    formats={["auto", "webp", "avif"]}
                    // imgStyle={{ objectFit: 'contain' }}
                    className={"w-full"}
                />
                <div className='px-4 md:px-0 pt-16'>
                    <h1 className='about-h1 pb-8'>{data?.h1 ? data?.h1 : data?.name}</h1>
                    <div className='service-editor' dangerouslySetInnerHTML={{ __html: data.content ? data.content : data.description }} />
                </div>

                {/* <div style={{ minHeight: "70vh" }} /> */}
            </div>
        </div>
    );
}

export default AboutUs;